var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    codeGroupCd: "LAW_ID",
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "lawId",
                    label: "LBL0010071",
                  },
                  model: {
                    value: _vm.searchParam.lawId,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "lawId", $$v)
                    },
                    expression: "searchParam.lawId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    type: "search",
                    comboItems: _vm.curHistroyItems,
                    itemText: "codeName",
                    itemValue: "code",
                    label: "현행/연혁",
                    name: "curHistroyCode",
                  },
                  model: {
                    value: _vm.searchParam.curHistroyCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "curHistroyCode", $$v)
                    },
                    expression: "searchParam.curHistroyCode",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    range: true,
                    defaultStart: "-2y",
                    defaultEnd: "2M",
                    label: "LBL0010103",
                    name: "promulgationPeriod",
                  },
                  model: {
                    value: _vm.searchParam.promulgationPeriod,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "promulgationPeriod", $$v)
                    },
                    expression: "searchParam.promulgationPeriod",
                  },
                }),
              ],
              1
            ),
            _c("div", {
              staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
            }),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          attrs: {
            title: "LBL0010096",
            tableId: "safetyControlDangerous",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  props.row.lawLink
                    ? [
                        _c("q-btn", {
                          staticClass: "tableinnerBtn",
                          attrs: {
                            flat: "",
                            icon: "launch",
                            align: "center",
                            color: "blue-6",
                            label: "",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.linkClick(props, col)
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "LBLSEARCH", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }